<script>
export default {
  metaInfo: {
    title: "Received CDRs",
  }
};
</script>

<script setup>
import ReceivedCdrsView from '@/components/dynamic/ReceivedCdrsView.vue';
</script>

<template>
  <ReceivedCdrsView/>
</template>

<style scoped>

</style>
